@use "sass:meta" as ---w2vxuqwv60o;/* Component SCSS */
@import "partial/general-settings/general-settings.scss";
@import "partial/my-subscription/my-subscription.scss";
@import "directive/topbar-settings/topbar-settings.scss";
@import "directive/file-drop-zone/file-drop-zone.scss";
@import "service/dialog/logo-editor/logo-editor.scss";
@import "service/dialog/active-site-dialog/active-site-dialog.scss";
@import "service/dialog/auth-request-dialog/auth-request-dialog.scss";
@import "service/dialog/activation-request-pos-dialog/activation-request-pos-dialog.scss";
@import "partial/kiosk-settings/kiosk-settings.scss";
@import "partial/shifts-settings/shifts-settings.scss";
@import "partial/e-commerce-settings/e-commerce-settings.scss";
@import "partial/batch-settings/batch-settings.scss";

/* Add Component SCSS Above */

md-content.settings-content {
    height: 100%;
}

.hostname {
    text-align: right;
}
.suffix-label-hostname {
    display: inline-block;
    position: relative;
    margin: 25px 0 0 0;
    vertical-align: middle;
}

;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:104");