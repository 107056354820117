@use "sass:meta" as ---w2vxuqwv60o;app-customer-form,
app-tilby-form-control-element,
suppliers-details{
    mat-form-field {
        mat-hint {
            display: none;
        }
    }
    mat-form-field:focus-within {
        mat-hint {
            display: block;
        }
    }
}

app-active-sale-action-buttons {
    .mat-button-toggle-button {
        height: 100%;
        min-height: 70px;
    }
}

app-cashregister-search-items {
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        // background-color: white !important;
        border: 1px solid #D1D5DB !important;
    }

    .mdc-line-ripple::before, .mdc-line-ripple::after {
        content : none;
    }
}

;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:390");