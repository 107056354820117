@use "sass:meta" as ---w2vxuqwv60o;.bedzzle-payments {
    height: 80%;
    min-height: 300px;
    @media screen and (max-width: 639px) {
        max-width: 100vw;
        max-height: 100vh;
        height: 100vh;
        width: 100vw;
    }
    .bedzzle-payments-payment {
        padding: 16px 0 16px 0;
        display: flex;
        flex-direction: column;
        display: flex;
        flex-direction: row;
        align-items: center;
        .bedzzle-payments-icon {
            margin-right: 8px;
            width: 40px;
            height: 40px;
        }
        .bedzzle-payments-divider {
            margin: 0 8px;
        }
        .bedzzle-payments-amount {
            font-weight: 500;
        }
        &.selected {
            background-color: $blue-100;
        }
    }
    .bedzzle-payments-message-box {
        min-height: 48px;
        background-color: $primary-tilby;
    }
    .bedzzle-no-account {
        color: $blue;
    }
    .bedzzle-payments-search {
        input {
            border: 0;
            border-bottom: 2px solid $blue-500;
            background-color: transparent;
        }
    }
    .bedzzle-payments-room {
        font-weight: bold;
        font-size: 36px;
    }
}
;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:66");