@use "sass:meta" as ---w2vxuqwv60o;.new-booking-dialog {
    .new-booking-dialog-content {
        &.sending-booking {
            opacity: 0.6;
        }
        .new-booking-dialog-quantity-row {
            min-height: 48px;
            md-icon {
                margin-right: 16px;
            }
            span {
                text-align: center;
            }
            .new-booking-dialog-quantity-button {
                align-items: center;
                background-color: $blue;
                border-radius: 50%;
                color: white;
                display: flex;
                height: 30px;
                justify-content: center;
                font-family: sans-serif;
                font-weight: 100;
                width: 30px;
            }
        }
        .new-booking-dialog-table {
            height: 48px;
            min-height: 48px;
            padding-left: 8px;
            border-bottom: 1px solid lightgrey;
            &.selected {
                background-color: $blue-100 !important;
            }
            &.unavailable {
                span {
                    color: #F44336 !important;
                }
            }
            .new-booking-dialog-table-name {
                color: $blue;
            }
            .new-booking-dialog-table-info {
                color: $text;
                font-size: .7em;
                margin: 1px 0;
                .new-booking-dialog-table-covers {
                    color: $blue;
                    min-width: 75px;
                }
            }
        }
        .new-booking-dialog-no-tables {
            span {
                text-align: center;
                color: $blue;
            }
        }
    }
}
;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:44");