@use "sass:meta" as ---w2vxuqwv60o;.fidelity-prizes-container {
    height: 100vh;
    overflow-y: auto
}

.fidelity-prizes-card{
    md-input-container{
        margin: 32px 0 0 0 !important;
    }
}
;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:130");