@use "sass:meta" as ---w2vxuqwv60o;$printer-icon-text: #FFC107; //Amber 500

.printer-categories-not-configured{
    color: $printer-icon-text;
}

.printer-categories-list{
    color: $text;
}

md-icon.printer-error-icon{
    color: $printer-icon-text !important;
}

md-icon.printer-timeout-icon{
    color: $printer-icon-text !important;
}

md-icon.printer-printed-icon{
    color: $teal-500 !important;
}

md-icon.printer-message-icon{
    color: $text;
    flex: 0 30px;
}

.printer-message{
    color: $text;
    font-size: .8em;
}

.printer-flex-message-icon{
    flex: 0 50px;
}

;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:30");