@use "sass:meta" as ---w2vxuqwv60o;.kiosk-catalog {
    .wrapper-background {
        position: relative;
        overflow: hidden;
        video.background {
            width: 100%;
            object-fit: cover;
        }
        img.background {
            height: 100%;
            width: 100%;
        }
        .grid-list-wrapper {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
        .padding-top-zero {
            padding-top: 0;
        }
        .padding-left-zero {
            padding-left: 0;
        }
        .category-list {
            overflow-y: scroll;
            overflow-x: hidden;
            .category-list-item {
                width: 100%;
                padding: 5%;
                text-align: center;
                background-color: #fff;
                border-bottom: 2px solid #ced1d0;
                &.selected {
                    font-weight: bold;
                }
            }
        }
        .grid-list-container {
            position: relative;
            overflow-y: scroll;
            overflow-x: hidden;
            .grid-list-container-header {
                position: sticky;
                top: 0;
                z-index: 10;
                background-color: #f7f7f7;
                padding: 0 16px;
                .category-name {
                    font-weight: bold;
                    font-style: italic;
                    margin: 0;
                }
            }
            md-grid-tile {
                .tile-content {
                    position: relative;
                    background-color: #fff;
                    height: 100%;
                    width: 100%;
                    .tile-image {
                        height: 60%;
                        width: 100%;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: 50% 0%;
                        background-origin: content-box;
                        padding-top: 3px;
                    }
                    .wrapper-btn {
                        position: absolute;
                        bottom: 40%;
                        right: 0;
                        border-radius: 25px;
                        width: 90%;
                        vertical-align: middle;
                        background-color: rgba($color: #fff, $alpha: 0.9);
                        border: 1px solid #ced1d0;
                        margin: 5%;
                        display: -webkit-flex;
                        display: -moz-box;
                        display: flex;
                        z-index: 0;
                        align-items: center;
                        -webkit-transition-property: all;
                        -moz-transition-property: all;
                        transition-property: all;
                        -webkit-transition-duration: .3s;
                        -moz-transition-duration: .3s;
                        transition-duration: .3s;
                        -webkit-transition-timing-function: linear;
                        -moz-transition-timing-function: linear;
                        transition-timing-function: linear;

                        &.item-empty {
                            max-width: max-content;
                            width: max-content;
                            background-color: transparent;
                            border-color: transparent;
                            .minus, .count {
                                -webkit-flex-basis: 0%;
                                -moz-flex-basis: 0%;
                                -ms-flex-basis: 0%;
                                flex-basis: 0%;
                            }
                            .minus button {
                                display: none;
                            }
                            .count span {
                                display: none;
                            }
                            .add {
                                -webkit-flex-basis: 100%;
                                -moz-flex-basis: 100%;
                                -ms-flex-basis: 100%;
                                flex-basis: 100%;
                            }
                        }
                        .minus, .add {
                            -webkit-flex: 1 1 30%;
                            -moz-flex: 1 1 30%;
                            -ms-flex: 1 1 30%;
                            flex: 1 1 30%;
                        }
                        .minus, .count, .add {
                            -webkit-transition-property: flex-basis;
                            -moz-transition-property: flex-basis;
                            transition-property: flex-basis;
                            -webkit-transition-duration: .3s;
                            -moz-transition-duration: .3s;
                            transition-duration: .3s;
                            -webkit-transition-timing-function: linear;
                            -moz-transition-timing-function: linear;
                            transition-timing-function: linear;
                        }
                        .minus {
                            text-align: left;
                        }
                        .count {
                            text-align: center;
                            -webkit-flex: 1 1 40%;
                            -moz-flex: 1 1 40%;
                            -ms-flex: 1 1 40%;
                            flex: 1 1 40%;
                        }
                        .add {
                            text-align: right;
                        }
                        .btn {
                            margin: 0;
                        }
                    }
                    .tile-item-footer {
                        text-align: left;
                        height: 40%;
                        width: 100%;
                        padding: 2%;
                        // background-color: rgb(159, 70, 70);
                        // color: #666;
                        .tile-item-footer-wrapper {
                            /*
                            position: relative;
                            height: 33.33%;
                            */
                            width: 100%;
                            overflow-wrap: break-word;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            .tile-item-name {
                                width: 100%;
                            }
                        }
                        .tile-item-name {
                            /*
                            position: absolute;
                            top: 0;
                            left: 0;
                            */
                            white-space: nowrap;
                            overflow-wrap: break-word;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: 0.85rem;
                            font-weight: bold;
                        }
                        .tile-item-desc {
                            color: #919191;
                            font-weight: normal;
                            font-style: italic;
                            font-size: 0.75rem;
                        }
                        .wrapper-info-icon {
                            text-align: right;
                            .info-icon {
                                height: 15px;
                                min-height: 100%;
                                width: 15px;
                                min-width: 100%;
                                vertical-align: baseline;
                            }
                        }
                    }
                    &.content-single {
                        height: 100%;
                        .tile-image {
                            width: 35%;
                            height: 100%;
                            position: relative;
                            padding: 0;
                            background-position: 50%;
                        }
                        .right {
                            width: 75%;
                            position: relative;
                            .tile-item-footer {
                                height: 100%;
                                .text-allergens {
                                    color: #919191;
                                    font-weight: normal;
                                    font-style: italic;
                                    font-size: 0.75rem;
                                }
                            }
                        }
                        .wrapper-btn {
                            margin: 0;
                            bottom: 5%;
                            right: 5%;
                        }
                    }
                    .item-no-stock {
                        color: #800;
                    }
                }
            }
        }
    }
}

;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:137");