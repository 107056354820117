@use "sass:meta" as ---w2vxuqwv60o;.printersmodule-modal-content {
    padding: 32px 24px 32px 24px !important;
    .label{
        margin-left: 0px;
        padding-bottom: 16px;
    }
    .md-label{
        margin-left: 36px;
    }
}

;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:53");