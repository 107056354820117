@use "sass:meta" as ---w2vxuqwv60o;.device-info-dialog {
    min-width: initial;

    .device-info-row {
        margin: 8px 0;

        .device-info-app-version {
            font-weight: 500;
            font-style: italic;
        }
        .device-info-value {
            font-weight: 500;
            margin-right: 16px;
        }
    }
    .nf-cert-logo {
        margin-left: 24px;
        a {
            text-align: center;
        }
        img {
            width: 125px
        }
    }
}
;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:38");