@use "sass:meta" as ---w2vxuqwv60o;app-tilby-keypad-display {
    .mdc-notched-outline__notch {
        display: none !important;
    }

    .mdc-text-field--outlined .mat-mdc-form-field-infix, .mdc-text-field--no-label .mat-mdc-form-field-infix {
        padding-top: 6px !important;
        padding-bottom: 10px !important;
    }
}

;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:400");