@use "sass:meta" as ---w2vxuqwv60o;md-dialog-content.add-edit-movement-content {
	.md-input[disabled] {
        background-image: none !important;
        color: black;
    }
    &.sending-movement {
        opacity: 0.6;
    }
}

.add-edit-movement-modal-width {
	min-width:60%;
}

.add-edit-movement-barcode-button {
    flex-shrink: 0;
}
;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:125");