@use "sass:meta" as ---w2vxuqwv60o;.activation-request-pos-dialog {
    p.title {
        max-width: 450px;
        margin-top: 0;
        text-align: left;
    }
    .error {
        color: #800;
    }
}

;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:113");