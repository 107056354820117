@use "sass:meta" as ---w2vxuqwv60o;barcode {
	.barcode-icon-enabled {
		background-color: white;
		border-radius: 4px;
	}
	.barcode-input {
		position:absolute;
		top:-50px;
		left: 0;
		height: 0;
		width: 0;
	}
}
;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:26");