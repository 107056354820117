@use "sass:meta" as ---w2vxuqwv60o;sidenav {
    .md-toolbar-tools{
        flex-wrap:wrap;
        display:flex;
        color: white !important;
    }
    i{
        color: black;
        margin-right: 34px;
        font-size: 24px;
    }
    .md-button-sidebar{
        text-transform: none;
        color: black !important;
        opacity: .6;
        overflow: visible !important;
        text-align: left;
        min-height: 48px;
        margin-top: 5px;
        margin-bottom: 5px;
        md-icon{
            color: black;
            margin-right: 16px;
        }
        span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            line-height: 18px;
        }
    }
    .md-sub-button-sidebar {
        margin-left: 36px;
        flex-shrink: 0;
    }
    .toolbar-header{
        min-height: 112px;
        padding: 10px 16px;
        .toolbar-header-top {
            .md-avatar{
                border-radius: 50%;
                height: 50px;
                width: 50px;
                background-color: white;
            }
            .toolbar-header-info{
                font-size: 14px;
                text-align: right;
            }
        }
        .toolbar-header-bottom {
            .username{
                font-size: 14px;
                font-weight: 700;
            }
            .userclass{
                font-size: 12px;
            }
            md-icon {
                right: 7px;
            }
        }
    }
    .sidenav-itemlist{
        //height: 100vh;
        overflow-y: auto;
    }
    .md-padding{
        padding: 6px 16px !important;
    }
    .app-version{
        float: right;
        color: black;
        opacity: .6;
        font-weight: 500;
        font-size: 12px;
        min-height: 36px;
        line-height: 36px;
        span {
            text-align: right;
            padding-right: 12px;
        }
    }
    .sidenav-badge {
        @extend .notification-badge;
        margin: 8px 0px 8px 0px;
        background-color: orange;
    }
}

;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:13");