@use "sass:meta" as ---w2vxuqwv60o;.printers-new {
    .printers-new-radio-container {
        md-input-container {
            margin-top: 0
        }
    }

    .printers-new-selector-container {
        label {
            margin-right: 8px;
        }
    }
}

.printersmodule-delete-button {
    width: 100%;
    height: 64px;
    min-height: 64px;
    border-top: 1px solid lightgrey;
}

.printers-search-btn{
    color: $blue-500;
    border: 1px solid $blue-500;
    background-color: white;
    width:200px;
    text-align: center;
    padding: 16px;
    margin: 0 auto;
}

.printers-search-box{
    color: $blue-500;
    border: 1px solid $blue-500;
    background-color: white;
    width:200px;
    text-align: center;
    padding: 16px;
    margin: 0 auto;
    border-top: none;
}


;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:50");