@use "sass:meta" as ---w2vxuqwv60o;/* Component SCSS */

@import "partial/stock-management/stock-management.scss";
@import "partial/stock-movements/stock-movements.scss";
@import "partial/stock-new-movement/stock-new-movement.scss";
@import "directive/topbar-stock/topbar-stock.scss";
@import "service/dialog/add-edit-movement/add-edit-movement.scss";

/* Add Component SCSS Above */

.stock-subheader {
    background-color: $subheader !important;
    min-height: 46px;
    .md-subheader-content {
        display: flex;
        flex-direction: row;
    }
}

.stock-no-results-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    .stock-no-results-placeholder-content {
        display: flex;
        align-items: center;
        img {
            width: 30%;
            min-height: 0;
        }
        .stock-no-results-placeholder-message {
            color: $blue;
            margin-top: 1em;
        }
    }
}
;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:120");