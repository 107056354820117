@use "sass:meta" as ---w2vxuqwv60o;/* Component SCSS */
@import "partial/printers/printers.scss";
@import "component/printers-list/printers-list.scss";
@import "partial/printers-new/printers-new.scss";
@import "partial/printers-detail/printers-detail.scss";
@import "partial/printers-general/printers-general.scss";
@import "service/dialog/new-printer/new-printer.scss";
@import "service/dialog/dgfe-result/dgfe-result.scss";
@import "service/dialog/shipping-invoice-dialog/shipping-invoice-dialog.scss";
@import "service/dialog/printer-upgrade-dialog/printer-upgrade-dialog.scss";
@import "service/dialog/daily-closing-dialog//daily-closing-dialog.scss";
/* Add Component SCSS Above */

.printers-tab {
	min-height: 48px !important;
}
;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:46");