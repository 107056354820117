@use "sass:meta" as ---w2vxuqwv60o;.general-document-printer-select-dialog {
    min-width: 400px;
    md-dialog-actions {
        justify-content: flex-start;
        padding-left: 24px;
    }
}

.general-document-printer-select-modal-content{
    padding: 0 !important;
    overflow-y: hidden;
}

.general-document-printer-select-no-items-placeholder{
    display: flex;
    flex:30%;
    justify-content: center;
    align-items: center;
    height: 80%;
    overflow: hidden;
    width: 100%;
    .general-document-printer-select-no-items-placeholder-content{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img{
            width: 30%;
            min-height:0;
        }
        .general-document-printer-select-no-items-placeholder-message{
            color: $blue;
        }
    }
}

.general-document-printer-select-row {
    height: 56px;
    min-height: 56px;
    padding-left: 16px;
    border-bottom: 1px solid lightgrey;
    .general-document-printer-select-row-name {
        color: $blue;
    }
    .general-document-printer-select-row-icon {
        flex: 0 52px;
        text-align: center;
    }
}

;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:31");