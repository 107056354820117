@use "sass:meta" as ---w2vxuqwv60o;.offline-img-container{
    width:100%;
    clear: both;
    text-align:center;
}

.offline-modal-text{
    margin-top: 30px;
}

;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:19");