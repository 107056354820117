@use "sass:meta" as ---w2vxuqwv60o;.item-list-selector-dialog {
    min-width: 400px;

    md-dialog-actions {
        justify-content: flex-start;
        padding-left: 24px;
    }

    .item-list-selector-modal-content{
        padding: 0 !important;
        overflow-y: hidden;
    }
    
    .item-list-selector-no-items-placeholder {
        display: flex;
        flex: 30%;
        justify-content: center;
        align-items: center;
        height: 80%;
        overflow: hidden;
        width: 100%;
        .item-list-selector-no-items-placeholder-content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            img{
                width: 30%;
                min-height: 0;
            }
            .item-list-selector-no-items-placeholder-message {
                color: $blue;
            }
        }
    }
    
    .item-list-selector-row {
        height: 56px;
        min-height: 56px;
        padding-left: 16px;
        border-bottom: 1px solid lightgrey;
        &:active {
            background-color: $blue-200 !important;
        }
        &.default-element {
            background-color: $blue-100;
        }
        .item-list-selector-row-name {
            color: $blue;
        }
        .item-list-selector-row-icon {
            flex: 0 52px;
            text-align: center;
        }
    }
}


;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:40");