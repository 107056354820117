@use "sass:meta" as ---w2vxuqwv60o;.printersmodule-form {
    padding: 24px 24px;
}

.printers-list-portrait {
    position: relative;
    .printers-list-container {
        overflow-y: auto;
    }
}

.printers-left-column {
    border-right: 1px solid lightgray;
    position: relative;
    .printers-list-container {
        overflow-y: auto;
    }
}

;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:47");