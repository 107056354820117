@use "sass:meta" as ---w2vxuqwv60o;.topbar-stock{
    md-autocomplete {
        md-input-container {
            padding: 0 2px;
        }
    }

    md-input-container {
        .md-errors-spacer {
            display: none;
        }
    }
    
    .new-movement-bar .stock-movements-filter-bar {
        font-size: .9em;
        align-items: flex-start;
    }
}

;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:124");