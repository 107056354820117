@use "sass:meta" as ---w2vxuqwv60o;@use '@angular/material' as mat;

app-search-bar {
    mat-form-field,
    mat-form-field > .mat-mdc-text-field-wrapper > .mat-mdc-form-field-flex,
    {
        border-radius: 10px;
    }

    mat-form-field {
        div[matformfieldnotchedoutline] {
            display: none;
        }
    }
}

;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:396");