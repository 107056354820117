@use "sass:meta" as ---w2vxuqwv60o;.kiosk-standby {
    position: relative;
    video {
        height: 100%;
        width: auto;
    }
    img.background {
        overflow: hidden;
        height: 100%;
        width: auto;
    }
    @media (orientation: landscape) {
        video {
            height: auto;
            width: 100%;
            object-fit: fill;
        }
        img.background {
            height: auto;
            width: 100%;
            object-fit: cover;
            object-position: top;
        }
    }
    .wrapper-logo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 30%;
        .logo {
            width: 50%;
            max-height: 50%;
        }
    }
    .section-flags {
        position: absolute;
        bottom: 0;
        margin: 0;
        width: 100%;
        background: rgba($color: #fff, $alpha: .4);
        padding: 2% 0;
        .btn-flag {
            img {
                height: 70px;
            }
        }
    }
    .group-btn-order-here {
        position: absolute;
        bottom: 20%;
    }
}

;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:135");