@use "sass:meta" as ---w2vxuqwv60o;.my-subscription {
    .settings-input-container-no-error {
        .md-errors-spacer {
            display: none;
        }
    }

    .dashboard {
        height: 100%;
        z-index: 1;
        @media (min-width: 960px) {
            padding: 16px 80px;
        }
        @media (min-width: 600px) and (max-width: 959px) {
            padding: 16px 40px;
        }
        @media (max-width: 599px) {
            padding: 16px 20px;
        }

        .dashboard-title {
            font-weight: normal;
            margin-left: 16px;
        }
    
        .dashboard-avatar {
            border-radius: 50%;
            height: 50px;
            width: 50px;
        }
    
        .dashboard-subheader{
            margin: 8px;
        }
    
        .dashboard-cards {
            md-card {
                height: unset;
                @media (min-width: 600px) {
                    height: 290px;
                }
            }
        }
    
        .card-row {
            margin: 5px 0;
        }
    
        .dashboard-card-headline {
            font-size: 20px;
            line-height: 24px;
            font-weight: bold;
            margin-left: 8px;
        }
    
        .today_total {
            font-size: 36px;
            line-height: 42px;
            font-weight: bold;
        }
    
        .card-amount-column-title {
            text-align:left;
            font-weight: bold;
        }

        .card-amount-column {
            min-width:70px;
            text-align:right;
        }
    }
}
;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:106");