@use "sass:meta" as ---w2vxuqwv60o;.historymodule-e-invoice-passive {
    .invoice-read{
        font-weight: bold;
    }

    md-card {
        md-card-content {
            border-bottom: 1px solid black;
            .title {
                font-size: 24px;
                margin-bottom: 8px;
            }
            .value {
                font-size: 32px;
                margin-bottom: 8px;
            }
        }
        &.selected-card{
            background-color:#EEEEEE;
        }
    }

    .historymodule-list-entry-color{
        .no-margin{
            margin:unset;
            cursor:pointer;
        }
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
    }
}

;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:92");