@use "sass:meta" as ---w2vxuqwv60o;.itemsmodule-list-item {
    border-bottom: 1px solid lightgrey;
    max-height: 68px;
    height: 68px;
    padding: 0 8px;
    overflow: hidden;
    .md-button {
        margin: 0;
        padding: 0;
    }
    md-checkbox {
        .md-container {
            top: 24px;
            left: 0;
            right: 6px;
            margin: 0;
        }
    }
    .itemsmodule-list-item-text {
        div {
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 22px;
        }
        .itemsmodule-list-item-name {
            color: $blue;
            cursor: pointer;
        }
        .itemsmodule-list-item-sku {
            color: $text;
            cursor: pointer;
        }
        .itemsmodule-list-item-price {
            color: $text;
            cursor: pointer;
        }
        .itemsmodule-list-item-department {
            color: $text;
            cursor: pointer;
        }
    }
    .itemsmodule-list-item-image {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin: 0 14px;
    }
}

;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:75");