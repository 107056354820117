@use "sass:meta" as ---w2vxuqwv60o;.einvoice-error-viewer {
    .historymodule-receipt{
        height: 50vh;
        @media (max-width: 639px) {
            height: 100%;
        }
    }

    .historymodule-receipt-box{
        text-align: center;
        overflow-y: auto;
    
        .receipt-e_invoice{
            text-align:left;
        }
    }
}
;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:99");