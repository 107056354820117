@use "sass:meta" as ---w2vxuqwv60o;.topbar-history {
    z-index: 2;

    span.historymodule-label{
        font-size: 16px;
        color: white;
    }
    
    .historymodule-filter-date {
        .md-errors-spacer {
            display: none;
        }
    }
    
    .historymodule-filter-input-grey{
        input {
            font-size: 16px !important;
        }
        label {
            color: $text !important;
            font-size: 16px;
        }
        span{
            font-size: 16px;
        }
        .md-errors-spacer {
            display: none;
        }
        md-chips-wrap {
            box-shadow: none;
            border-bottom: 1px solid black;
            &.md-focused {
                border-bottom: 2px solid black;
            }
        }
    }
}

;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:89");