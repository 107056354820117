@use "sass:meta" as ---w2vxuqwv60o;.update-required-content {
    padding: 24px;
    h1 {
        text-align: center;
    }
    .update-required-logo {
        height: 7.5em;
    }
    .update-required-text {
        font-size: 1.5em;
        margin-bottom: 1em;
        text-align: center;
    }
    .update-required-button {
        background-color: green !important;
        font-size: 1.5em;
        padding: 8px;
    }
    .update-required-progress-container {
        width: 75%;
    }
}
;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:18");