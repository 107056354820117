@use "sass:meta" as ---w2vxuqwv60o;.item-details {
	.item-details-category-title {
		color: $blue-500;
	}
	
	.item-details-color-preview {
		width: 16px;
		height: 16px;
		border-radius: 100%;
	}
	
	.item-details-show-more {
		color: rgba(0,0,0,0.54);
		line-height: 24px;
	}
	
	.item-details-radio-button-error{
		font-size: 0.8em;
		margin-top: 8px;
		color: red;
	}
	
	.fieldset-items-has-button {
		legend {
			padding: 0;
		}
		.fieldset-items-button {
			margin: 0 !important;
			padding: 0 !important;
			border: 1px solid lightgray;
		}
	}
	
	.item-details-bom-component {
		button label{
			font-size: .8em !important;
		}
	}
	
	.item-details-bom-cost {
		margin-right: 12px;
		padding-top: 8px;
		min-width: 60px;
	}
	
	.item-channel-row {
		@media(max-width: 959px) {
			margin-bottom: 16px;
		}
	}
	
	.item-channel-icon {
		border-radius: 50%;
		height: 40px;
		width: 40px;
		background-color: $blue;
		margin-right: 8px;
	}
	
	.itemsmodule-images{
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		.itemsmodule-image-container{
			position: relative;
			margin: 16px 32px 16px 0;
			.itemsmodule-remove-image{
				position: absolute;
				top: -10px;
				right: -10px;
				width: 20px;
				height: 20px;
				text-align: center;
				border-radius: 100%;
				background: white;
			}
		}
	}
	
	.scl-table {
		width: 100%;
		border: 1px solid lightgrey;
	}

    .optionLeanPMS {
        padding-right: 130px;
        @media(max-width: 599px) {
            padding-right: 36px;
		}

    }

    .iconLeanPMS {
        right: 100px !important;
        @media(max-width: 599px) {
            right: 2px !important;
		}
    }
}

;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:78");