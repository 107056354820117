@use "sass:meta" as ---w2vxuqwv60o;@use 'node_modules/ag-grid-community/styles/index' as ag;
@use '@angular/material' as mat;
@use 'sass:map';

@include ag.grid-styles((
    theme: material,
));


@mixin color($theme) {
    $color-config: mat.get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');
    $warn-palette: map.get($color-config, 'warn');
    $foreground: map.get($color-config, foreground);
    $background: map.get($color-config, background);
    $text: mat.get-color-from-palette($primary-palette,'100-contrast');
    $is-dark-theme: map.get($color-config, 'dark-theme');

    *{

        .ag-theme-material .ag-tabs,
        .ag-theme-material .ag-list.ag-select-list.ag-ltr.ag-popup-child,
        .ag-theme-material [aria-label="Menu Contestuale"]
        {
            background: #{mat.get-color-from-palette($background, background)};
        }

        // .dark-theme {
        //     .ag-theme-material .ag-tabs,
        //     .ag-theme-material .ag-list.ag-select-list.ag-ltr.ag-popup-child,
        //     .ag-theme-material [aria-label="Menu Contestuale"]
        //     {
        //         background: #{mat.get-color-from-palette($background, background)};

        //         ::placeholder {
        //             color: #{mat.get-color-from-palette($foreground, text)} !important;
        //         }
        //     }

        // }

        @media screen and (min-width: 480px) {
            ag-grid-angular > .ag-root-wrapper.ag-layout-normal.ag-ltr {
                border-top-left-radius: 10px;
            }
        }

        .ag-theme-material {
            --ag-material-primary-color: #{mat.get-color-from-palette($primary-palette)} !important;
            --ag-material-accent-color: #{mat.get-color-from-palette($accent-palette)} !important;
            --ag-foreground-color: #{mat.get-color-from-palette($foreground, foreground)} !important;
            --ag-background-color: #{mat.get-color-from-palette($background, background)} !important;
            --ag-row-hover-color: #{mat.get-color-from-palette($primary-palette, 100)} !important;
            --ag-checkbox-indeterminate-color: #{mat.get-color-from-palette($accent-palette)} !important;
        }

        .ag-row-background__selected {
            background-color: #{mat.get-color-from-palette($primary-palette, 100)} !important;
        }

        .ag-dark-row-background__selected {
            background-color: #{mat.get-color-from-palette($primary-palette, 100)} !important;
        }

        .ag-row-background__unselected {
            background: none;
        }

        //--------------------------------------//

        .ag-header-cell-filtered {
            background-color: #1b6d85 !important;
            color: #fff !important;
        }

        .ag-header-cell-filtered span {
            color: #fff !important;
        }

        customers-showcase, suppliers-showcase, history-sales-showcase, giftcard-showcase, app-settings-um-static-token, history-cash-movements, app-file-importer, app-file-exporter, promotions-showcase {
            .ag-header-cell[col-id="params"] {
                padding-left: 10px !important;
                padding-right: 10px !important;
            }

            .ag-cell[col-id="params"] {
                padding-left: 5px !important;
                padding-right: 0px !important;
            }

            .ag-header-cell[col-id="checkbox"] {
                padding-left: 16px !important;
            }

            .ag-cell[col-id="checkbox"] {
                padding-left: 16px !important;
            }
        }

        div.ag-cell.ag-cell-normal-height.ag-cell-not-inline-editing.ag-cell-value:has(>app-skeleton-grid-row>ngx-skeleton-loader) {
            width: auto !important;
        }

    }

}

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);
    @if $color-config != null {
        @include color($theme);
    }
}

;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:373");