@use "sass:meta" as ---w2vxuqwv60o;.shifts {
    .shifts-content {
        width: 100%;
        height: 100vh;
        overflow-x: hidden !important;
        overflow-y: auto;
        padding-top: 0;
        .shifts-left-column {
            border-right: 1px solid lightgrey;
            position: relative;
            .shifts-list-container {
                height: 100%;
                overflow-y: auto;
                .shifts-element-row {
                    height: 100px;
                    min-height: 100px;
                    padding: 8px;
                    @media(max-width: 599px) {
                        height: 60px;
                        min-height: 60px;
                    }
                    &.shifts-element-selected {
                        background-color: $blue-100;
                        color: white;
                    }
                    .shifts-element-name {
                        color: $blue;
                        padding: 0 16px;
                        @media(max-width: 599px) {
                            display: none;
                        }
                    }
                }
            }
        }

        .shifts-right-column {
            height: 100%;
            .shifts-form {
                .md-input[disabled] {
                    background-image: none !important;
                    color: black;
                }
            }
        }
    }

    .md-datepicker-flex .md-input-messages-animation .md-input-message-animation {
        margin-left: 0;
    }
}

;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:115");