@use "sass:meta" as ---w2vxuqwv60o;/* Component SCSS */
@import "partial/items-showcase/items-showcase.scss";
@import "directive/topbar-items/topbar-items.scss";
@import "directive/itemsmodule-list-item/itemsmodule-list-item.scss";
@import "partial/categories/categories.scss";
@import "partial/item-details/item-details.scss";
@import "partial/departments/departments.scss";
@import "partial/components/components.scss";
@import "partial/raw-materials/raw-materials.scss";
/* Add Component SCSS Above */

md-content.itemsmodule-content {
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-top: 0;
}

.itemsmodule-topbar{
    position: relative !important;
    flex-shrink: 0;
}

.itemsmodule-row-container{
    display: flex;
    flex-direction: row;
}

.itemsmodule-left-column{
    border-right: 1px solid lightgray;
    position: relative;
    display: flex;
    md-radio-button{
        margin-bottom: 32px;
        margin-left: 2px;
    }
}

.itemsmodule-delete-button {
    width: 100%;
    min-height: 64px;
    display: flex;
    border-top: 1px solid lightgrey;
}

.itemsmodule-radio-container{
    overflow-y: auto !important;
    width: 100%;
    padding-top: 16px;
    md-radio-button .md-label, .md-switch-thumb .md-label {
        position: relative;
        display: inline-block;
        margin-left: 40px;
        margin-right: 10px;
        vertical-align: middle;
        white-space: normal;
        pointer-events: none;
        width: auto;
    }

    md-radio-button .md-container, .md-switch-thumb .md-container {
        position: relative;
        top: 10px;
        display: inline-block;
        width: 16px;
        height: 16px;
        left: 16px;
        cursor: pointer;
    }
}

.itemsmodule-save {
    background: $blue-100;
    padding-left: 24px;
    align-items: center;
    flex-shrink: 0;
    h3{
        color: $blue-500;
        font-weight: 400;
    }
}

.itemsmodule-form{
    overflow-y: auto;
    padding: 8px 24px;
}

.itemsmodule-favorite-enabled {
    color: #FF0084;
}

.itemsmodule-favorite-disabled {
    color: grey;
}

;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:72");