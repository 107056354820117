@use "sass:meta" as ---w2vxuqwv60o;.modal-dialog-header {
    max-height: 56px;
    min-height: 56px;
    span {
        font-size: 0.8em;
        &.title {
            margin-left: 16px;
            text-overflow: ellipsis;
            overflow-x: hidden !important;
            white-space: nowrap;
        }
    }
    button {
        margin: 0 6px !important;
    }
}
//END modal header primary colours theme

md-dialog md-dialog-content {
    padding: 24px 32px;
}

.modal-dialog {
    @media screen and (max-width: 639px) {
        max-width: 100vw;
        width: 100vw;
        max-height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
        height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    }
}

md-dialog{
    min-width:41%;
    overflow-x: hidden !important;
}

.select-margin{
    margin-right:10px;
}

;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:11");