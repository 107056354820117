@use "sass:meta" as ---w2vxuqwv60o;.kiosk-settings {
    md-input-container {
        padding: 10px;
    }

    .kiosk-checkbox{
        padding: 10px;
    }
}

;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:114");