@use "sass:meta" as ---w2vxuqwv60o;@import "component/kiosk-header/kiosk-header.scss";
@import "partial/standby/standby.scss";
@import "partial/service-selection/service-selection.scss";
@import "partial/catalog/catalog.scss";
@import "partial/cart/cart.scss";
@import "partial/document-selection/document-selection.scss";
@import "partial/customer-selection/customer-selection.scss";
@import "partial/payment-selection/payment-selection.scss";
@import "partial/confirmation/confirmation.scss";
@import "service/dialog/add-variations/add-variations.scss";
@import "service/dialog/show-allergens/show-allergens.scss";

.kiosk-plain-button {
    padding: 0 12px;
    border: 1px solid grey;
    border-radius: 5px;
    text-align: center;
    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}

.kiosk-footer-totals {
    background-color: #efefef;
    .kiosk-totals {
        color: #5F5F5F;
        padding: 1%;
        align-items: flex-start;
        font-size: 150%;

    }
    .kiosk-plain-button {
        line-height: 36px;
    }
    .btn {
        height: 3em;
        font-size: 150%;
    }
}

.kiosk-footer-button-placeholder {
    padding: 0 12px;
    min-width: 64px;
}

.kiosk-wrapper-btn {
    width: 100vw;
    .btn {
        position: relative;
        height: 3em;
        width: 90%;
        margin-bottom: 1.5em;
        font-size: 150%;
        .icon-style {
            width: 40px;
            height: 40px;
            font-size: 40px;
            &:last-of-type {
                margin-right: 16px;
            }
        }
    }
}

;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:133");