@use "sass:meta" as ---w2vxuqwv60o;@use '@angular/material' as mat;

app-history-sale-detail{
    mat-button-toggle.custom-interline button span{
        line-height: 2rem !important;
    }
    mat-button-toggle#header:first-child.custom-interline button {
        text-align: left !important;
        span{
            line-height: 1rem !important;
        }
    }
}

body:has(history-sales-showcase):has(app-history-sale-detail):has(app-sale-documents-viewer-dialog)
{
    .md-dialog-container:has(md-dialog[aria-label="Print Error Fiscal"]){
        z-index:9999 !important;
    }
}

;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:391");