@use "sass:meta" as ---w2vxuqwv60o;.e-invoice-setup {
    .send-mode-title {
        font-weight: bold;
    }

    .e-invoice-setup-error-message {
        background-color: #F5F5F5;
        min-height: 48px;
        padding: 0 16px;
        text-align: center;
        white-space: pre-wrap;
    }

    .working {
        opacity: 0.6;
    }

    h2,h3 {
        text-align: center;
    }

    h4 {
        font-weight: normal;
    }

    .digitalhub-managed-setup {
        span {
            margin-bottom: 8px;
        }
    }

    .sms-confirm-wait-label {
        margin-bottom: 8px;
        text-align: center;
    }
}
;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:100");