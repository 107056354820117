@use "sass:meta" as ---w2vxuqwv60o;md-content.bookings-showcase-content {
    overflow: hidden !important;
    z-index: 1;
}

.progress-wrapper {
    position: absolute;
    top: 50%;
    left: 25%;
    width: 50%;
    height: 150px;
    background: white;
    border: 1px solid white;
    border-radius: 10px;
    z-index: 2;
}

;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:45");