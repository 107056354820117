@use "sass:meta" as ---w2vxuqwv60o;.historymodule-filter-date{
    .md-datepicker-input-container{
        width:100%;
    }
}

.historymodule-e-invoice{
    .toolbar-options{
        height: auto;
        max-height: auto;

        @media (max-width: 959px) and (min-width: 0) and (orientation: portrait){
            height: unset;
            max-height: unset;

            md-input-container{
                margin:5px 0;
            }
        }
    }
}
;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:90");