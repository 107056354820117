@use "sass:meta" as ---w2vxuqwv60o;.stock-new-movement {
    .stock-new-movement-content-list {
        width: 100%;
        height: 100vh;
        overflow-y: auto;
        // justify-content: center;
        .stock-new-movement-list-movement {
            position: relative;
            transition: background-color 0.15s linear;
            margin: 0;
            width: 100%;
            display: flex;
            border-bottom: 1px solid lightgrey;
            height: 64px;
            min-height: 64px;
            padding: 0 16px;
            .stock-new-movement-list-movement-color {
                color: $text !important;
            }
            .stock-new-movement-list-movement-fields {
                md-autocomplete-wrap {
                    border: 1px solid lightgrey;
                }
                md-autocomplete-wrap {
                    box-shadow: none;
                }
                div:not(:last-child) {
                    > input {
                        border: 1px solid lightgrey;
                        height: 40px;
                        text-align: right;
                    }
                    padding-right: 16px;
                }
            }
        }
    }
    .stock-rotate-screen-placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        .stock-rotate-screen-placeholder-content {
            display: flex;
            align-items: center;
            md-icon {
                color: $blue;
                transform: scale(5);
            }
            .stock-rotate-screen-placeholder-message {
                color: $blue;
                margin-top: 4em;
                padding: 0 16px;
                text-align: center;
            }
        }
    }
}
;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:123");