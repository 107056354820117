@use "sass:meta" as ---w2vxuqwv60o;@use '@angular/material' as mat;
@use 'sass:map';

@mixin color($theme) {
    $color-config: mat.get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');
    $warn-palette: map.get($color-config, 'warn');
    $foreground: map.get($color-config, foreground);
    $background: map.get($color-config, background);
    $text: mat.get-color-from-palette($foreground, 'text');
    $type: mat.get-theme-type($theme);

    $primary-color: mat.get-color-from-palette($primary-palette);
    $primary-color-contrast: mat.get-color-from-palette($primary-palette, '500-contrast');
    $primary-color-200: mat.get-color-from-palette($primary-palette, 200);
    $background-color: mat.get-color-from-palette($background,'background');
    $custom-background: if($type == dark, #000, $background-color);
    $background-dialog-color: mat.get-color-from-palette($background,'dialog');


    // @debug "$color-config body" $color-config;
    // @debug "$color-config body" $custom-background;
    .tilby-body-primary-color{color:$primary-color !important;}
    .tilby-body-background-primary-color{background:$primary-color !important; color: $primary-color-contrast !important;}
    .tilby-body-background-primary-color-disabled{background:$primary-color-200 !important; color: $primary-color-contrast !important;}
    .tilby-dialog-background{background: $background-dialog-color}
    .tilby-dialog-text{color: $text}

    body {
        --tilby-body-text-color: $text;
    }

    //NEW-MATERIAL-SIDENAV(BODY DI TILBY)
    md-sidenav, //for downgrade Menu
    app-new-material-sidenav,
    new-sidenav{

        div#contentContainer {
            background-color: $custom-background;
            border-top-left-radius: 10px;
        }
    }

    app-settings-model-document {
        .group-button {
            .button-self {
                &.isActive {
                    background-color: $primary-color;
                }
                &:hover {
                    background-color: light($primary-color);
                }
            }
            .mat-mdc-icon-button {
                color: $primary-color-contrast;
            }
        }
    }

    //CASHREGISTER
    app-cashregister,
    cashregister-new-component{
        :has(tilby-shop-cart) {
            .tilby-body-primary {
                color: $primary-color;
            }

            .order-menu-mode {
                border-top: 1.1px solid $background-color;
            }

            app-action-bar {
                background: $background-color;
            }
        }
    }
}

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);
    @if $color-config != null {
        @include color($theme);
    }
}

;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:378");