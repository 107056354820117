@use "sass:meta" as ---w2vxuqwv60o;.cdk-overlay-container .magic-form-dialog app-magic-form-dialog tilby-magic-form > form > div{
        gap: 0 !important;
    }

.newTableClass{
    width: 100vw;
    mat-bottom-sheet-container{
        width: 100%;
        min-width: unset;
        max-width: unset;
    }
}

#room_floor_id img {
    border: 1px solid black;
}

;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:395");