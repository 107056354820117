@use "sass:meta" as ---w2vxuqwv60o;.card-notice {
    text-align: center;
    padding: 3rem;
    @media screen and (max-width: 400px) {
        padding: 1rem;
    }
    .notice-1 {
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .notice-2 {
        margin-top: 0;
        padding-top: 0;
    }
}

;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:118");