@use "sass:meta" as ---w2vxuqwv60o;.printers-light-text{
    color: $text;
}

.printers-lighter-text{
    color: #BDBDBD;
}

.printers-text{
    color: $text-title;
    word-wrap: break-word;
}
;@include ---w2vxuqwv60o.load-css("sass-embedded-legacy-load-done:51");